<!--初始化退货-->
<template>
    <div class="SysOut" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: left" v-model="form" size="small">
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select v-model="form.deptCode" filterable size="small">
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in meta.storeDepts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="searchHandleQuery"
                size="small"
                v-if="hasPrivilege('menu.purchase.sysOut.open')"
                >查询
            </el-button>
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.purchase.sysOut.create')"
                >新建
            </el-button>
            <el-button
                type="primary"
                @click="handleAudit"
                size="small"
                v-if="hasPrivilege('menu.purchase.sysOut.review')"
                >审核
            </el-button>
            <el-button type="primary" @click="handleOut" size="small" v-if="hasPrivilege('menu.purchase.sysOut.stock')">
                出库
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.purchase.sysOut.export')"
            >
                导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
            <ef-review-biz ref="review" baseUrl="/purchase/sysOut" @completed="handleQuery" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                ref="table"
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="460"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />

                <el-table-column prop="code" label="编码" width="180" />
                <el-table-column prop="deptName" label="机构名称" width="180" />
                <el-table-column prop="reviewStatus" label="审核状态" width="180">
                    <template slot-scope="scope">
                        <span>{{ scope.row.reviewStatus | reviewStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="stockStatus" label="单据状态" width="180">
                    <template slot-scope="scope">
                        <span>{{ scope.row.stockStatus | stockOutStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="creator" label="创建人" width="180" />
                <el-table-column prop="createTime" label="创建时间" width="140" />
                <el-table-column label="操作" min-width="275" header-align="center">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.purchase.sysOut.open')"
                            >查看
                        </el-button>
                        <el-button
                            size="mini"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            v-if="hasPrivilege('menu.purchase.sysOut.edit')"
                            :disabled="scope.row.reviewStatus === 2"
                            >编辑
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row)"
                            v-if="hasPrivilege('menu.purchase.sysOut.delete')"
                            >删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import EfReviewBiz from 'components/EfReviewBiz';
import { DeptTypeEnum } from 'js/DeptCommon';

export default {
    name: 'SysOut',
    components: { EfReviewBiz },
    data() {
        return {
            form: {
                deptCode: '',
                deptType: '',
                search: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            auditCodes: [],
            audit: {
                auditDate: '',
                advice: '1',
                remark: '',
            },
            loading: false,
            tableData: [],
            url: {
                page: '/purchase/sysOut/page',
                review: '/purchase/sysOut/review/',
                delete: '/purchase/sysOut/delete',
            },
            meta: {
                storeDepts: [],
            },
        };
    },
    mounted() {
        (async () => {
            this.meta.storeDepts = await this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE);
            this.handleQuery();
        })();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '初始化退货', '/purchase/sysOut/export', this.form, codes);
        },
        handleAudit() {
            this.$refs.review.open(this.$refs.table.selection);
        },
        handleOut() {
            const _this = this;
            const selection = this.$refs['table'].selection;
            if (selection.length !== 1) {
                this.$message.error('选择单据数量必须为一条');
                return;
            }
            const selected = selection[0];
            if (selected.reviewStatus != 2) {
                this.$message.error('请勾选已审核商品');
                return;
            }
            if (selected.stockStatus != 0) {
                this.$message.error('商品仅能出库一次');
                return;
            }
            if (Util.isEmpty(selected.code)) {
                this.$message.error('请勾选单据');
                return;
            }
            if (selected.reviewStatus != 2) {
                this.$message.error('请选择已审核商品行列');
                return;
            }
            if (selected.stockStatus != 0) {
                this.$message.error('商品仅能出库一次');
                return;
            }
            this.$confirm('确认出库该单据么?出库后该单据商品库存将立即变更,该操作不可撤销,请小心操作!!!', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    UrlUtils.PostRemote(this, '/purchase/sysOut/out', { codes: selected.code }, null, () => {
                        _this.$message.success('保存成功');
                        _this.handleQuery();
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消出库',
                    });
                });
        },
        out() {
            const _this = this;
            let _params = {};
            if (this.$refs['table'].selection[0]) {
                _params = {
                    codes: this.$refs['table'].selection[0].code,
                };
            } else {
                _params = {
                    codes: this.codes,
                };
            }

            _this.$http
                .post('/purchase/sysOut/out', _params)
                .then((res) => {
                    if (res.data.status == 200) {
                        this.$confirm('审核成功')
                            .then(() => {})
                            .catch(() => {
                                this.handleQuery();
                            });
                    } else {
                        _this.$message.error(res.data.message);
                    }
                })
                .catch((rst) => {
                    _this.$message.error(rst.response.data.message);
                });
        },
        searchHandleQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleCreate() {
            Util.nameJump(this, 'menu.purchase.sysOut.create', ['商品管理', '初始化退货管理', '新建初始化退货']);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.purchase.sysOut.detail', ['商品管理', '初始化退货管理', '初始化退货详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            if (row.reviewStatus == 0) {
                Util.nameJump(this, 'menu.purchase.sysOut.edit', ['商品管理', '初始化退货管理', '编辑初始化退货'], {
                    form: row,
                    code: row.code,
                });
            } else {
                this.$message.error('非待审核状态不可编辑');
            }
        },

        handleDelete(row) {
            if (row.reviewStatus == 0) {
                UrlUtils.DeleteRemote(this, this.url.delete, row.code);
            } else {
                this.$message.error('非待审核状态不可删除');
            }
        },
        handleSizeChange(size) {
            this.form.page = 1;
            this.form.limit = size;
            this.handleQuery();
        },
        handleCurrentChange(page) {
            this.form.page = page;
            this.handleQuery();
        },
        handlePrevClick(page) {
            this.form.page = page;
        },
        handleNextClick(page) {
            this.form.page = page;
        },
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
    },
};
</script>

<style scoped>
.SysOut .el-form-item {
    margin-bottom: 0;
}
</style>
